<template>
  <div class="apply-warp">
    <a-spin :spinning="loadding">
      <div class="content">
        <div class="component-tit">
          {{ $t('LB_AuditResult') }}
          <!-- 审核结果 -->
          <div class="back" @click="back"><RollbackOutlined />{{ $t('go_back') }}</div>
          <!-- 返回 -->
        </div>
        <div class="check">
          <a-row :gutter="16">
            <a-col :span="8">
              <span class="l">{{ $t('CM_Status') }}：</span>
              <!-- 状态： -->
              <span class="r status">{{ status[dataInfo.status] }}</span>
            </a-col>
            <a-col :span="16">
              <span class="l">{{ $t('LB_Favorite_Score') }}：</span>
              <!-- 评分： -->
              <span class="r"
                ><span class="score">{{ dataInfo.score }}</span
                >{{ $t('exam.score') }}</span
              >
              <!-- 分 -->
            </a-col>
            <a-col :span="8">
              <span class="l">{{ $t('teacher.assessment_level') }}：</span>
              <!-- 评定等级： -->
              <span class="r">{{ dataInfo.levelName }}</span>
            </a-col>
            <a-col :span="16">
              <span class="l">{{ $t('teacher.result') }}：</span>
              <!-- 聘期结果： -->
              <span class="r">{{ tenureType[dataInfo.tenureType] }}</span>
            </a-col>
            <a-col :span="8">
              <span class="l">{{ $t('teacher.evaluation_date') }}：</span>
              <!-- 评价日期： -->
              <span class="r">{{ dateFormat(dataInfo.auditTime) }}</span>
            </a-col>
            <a-col :span="16">
              <span class="l">{{ $t('CM_LB_AttachmentFile') }}：</span>
              <!-- 附件： -->
              <span class="r down" @click="down">{{ dataInfo.file }}</span>
            </a-col>
            <a-col :span="24">
              <span class="l">{{ $t('CM_LB_Reviews') }}：</span>
              <!-- 评语： -->
              <span class="r">{{ dataInfo.reason }}</span>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>

    <a-spin :spinning="loadding">
      <div class="content">
        <div class="component-tit">{{ $t('teacher.lecturer_infor') }}</div>
        <!-- 讲师信息 -->
        <a-form
          class="form-box"
          layout="vertical"
          :model="dataInfo"
          :colon="false"
        >
          <a-row :gutter="16">
            <a-col :span="12">
              <!-- 头像 -->
              <a-form-item :label="$t('CM_Header')" name="portrait">
                <div class="avatar-box">
                  <div class="left">
                    <img :src="dataInfo.portrait" v-if="dataInfo.portrait" />
                    <img src="@/assets/image/teacher_avatar.png" v-else />
                  </div>
                  <div class="right"></div>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 讲师等级 -->
              <a-form-item
                :label="$t('teacher.lecturer_level')"
                name="gender"
                style="margin-top: 88px"
              >
                <a-input :value="dataInfo.levelName" disabled>
                  <template #prefix>
                    <img
                      class="prefix-icon"
                      :src="dataInfo.levelPicture"
                      alt="icon"
                    />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 姓名 -->
              <a-form-item :label="$t('teacher.name')" name="tel">
                <OpenData
                  type="userName"
                  :openid="dataInfo.lecturerName"
                  v-if="
                    (platformConfig.platform == 26 ||
                      platformConfig.platform == 31) &&
                    dataInfo.lecturerType == 1
                  "
                />
                <a-input v-else :value="dataInfo.lecturerName" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 讲师分类 -->
              <a-form-item :label="$t('teacher.lecturer_classification')" name="tel">
                <a-input :value="dataInfo.folderName" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 性别 -->
              <a-form-item :label="$t('teacher.sex')" name="gender">
                <a-input
                  :value="
                    dataInfo.gender == 1
                      ? $t('teacher.man_woman', 1)
                      : dataInfo.gender == 2
                      ? $t('teacher.man_woman', 2)
                      : ''
                  "
                  disabled
                />
                <!-- 男 女 -->
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="!ISHTSY">
              <a-form-item :label="$t('teacher.mobile')" name="mobile">
                <a-input :value="dataInfo.mobile" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="!ISHTSY">
              <!-- 邮箱 -->
              <a-form-item :label="$t('teacher.email')" name="email">
                <a-input :value="dataInfo.email" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="!ISHTSY">
              <!-- 座机号 -->
              <a-form-item :label="$t('teacher.tel')" name="tel">
                <a-input :value="dataInfo.tel" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 职务 -->
              <a-form-item :label="$t('teacher.position')" name="positionRank">
                <a-input :value="dataInfo.positionRank" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <!-- 出生日期 -->
              <a-form-item :label="$t('teacher.birthday')" name="birthday">
                <a-input :value="dataInfo.birthday" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- 讲师简介 -->
              <a-form-item :label="$t('teacher.lecturer_intro')" name="intro">
                <a-textarea :rows="4" :value="dataInfo.intro" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- 座右铭 | 专业技术职务 -->
              <a-form-item
                :label="!ISHTSY ? $t('teacher.motto') : '专业技术职务'"
                name="motto"
              >
                <a-textarea :rows="4" :value="dataInfo.motto" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- 授课方向 -->
              <a-form-item :label="$t('teacher.teachingDirection')" name="teachingDirection">
                <a-textarea
                  :rows="4"
                  :value="dataInfo.teachingDirection"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- '擅长领域' : '型号项目队伍职级' -->
              <a-form-item
                :label="!ISHTSY ? $t('teacher.expertAreas') : '型号项目队伍职级'"
                name="expertAreas"
              >
                <a-textarea :rows="4" :value="dataInfo.expertAreas" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- 专家称号 -->
              <a-form-item :label="$t('teacher.expertTitle')" name="expertTitle">
                <a-tag v-for="(tag, i) in dataInfo.expertTitleArr" :key="i">
                  {{ tag }}
                </a-tag>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- '研究领域' : '专业队伍职级' -->
              <a-form-item
                :label="!ISHTSY ? $t('teacher.researchAreas') : '专业队伍职级'"
                name="researchAreas"
              >
                <a-textarea
                  :rows="4"
                  :value="dataInfo.researchAreas"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- '工作简历' : '工作履历' -->
              <a-form-item
                :label="!ISHTSY ? $t('teacher.workExperience') : '工作履历'"
                name="workExperience"
              >
                <a-textarea
                  :rows="4"
                  :value="dataInfo.workExperience"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <!-- '其他' : '教育履历' -->
              <a-form-item :label="!ISHTSY ? $t('teacher.others') : '教育履历'" name="others">
                <a-textarea :rows="4" :value="dataInfo.others" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { teacherApplyDetail } from "@/api/teacher";
import { dateFormat, viewOrDownFile, handelSemicolonWrap } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const route = useRoute();
    const state = reactive({
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      applyId: Number(route.query.applyId),
      loadding: true,
      status: {
        0: $t('teacher.under_review'), // 审核中
        1: $t('teacher.review_pass'), // 审核通过
        2: $t('teacher.under_review'), // 审核中
        3: $t('teacher.review_rejected'), // 审核驳回
      },
      tenureType: {
        1: $t('teacher.newly_hired'), // 新聘
        2: $t('teacher.renew'), // 续聘
        3: $t('teacher.promotion'), // 晋升
        4: $t('teacher.downgrade'), // 降级
        5: $t('teacher.fired'), // 解聘
      },
      dataInfo: {},
    });

    let f = {};
    teacherApplyDetail(state.applyId).then((res) => {
      state.loadding = false;
      let d = res.data;
      if (d.courseFile) {
        f = JSON.parse(d.courseFile)[0];
        d.file = f.fileName + f.filePath.substr(f.filePath.lastIndexOf("."));
      }
      if (d.birthday) {
        let b = d.birthday + "";
        d.birthday = b
          ? b.slice(0, 4) + "-" + b.slice(4, 6) + "-" + b.slice(6)
          : "";
      } else {
        d.birthday = "";
      }
      d.expertTitleArr = d.expertTitle ? d.expertTitle.split(",") : [];
      if (ISHTSY) {
        d.workExperience = handelSemicolonWrap(d.workExperience);
        d.others = handelSemicolonWrap(d.others);
      }
      state.dataInfo = d;
    });

    const down = () => {
      let files = state.dataInfo.courseFiles;
      if (files && files.length) {
        let item = files[0];
        viewOrDownFile(item);
      }
    };

    const back = () => {
      history.go(-1);
    };

    return {
      ISHTSY,
      dateFormat,
      ...toRefs(state),
      down,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.apply-warp {
  padding: 32px 0;
  .content {
    .mixinWrap();
    background-color: #fff;
    padding: 30px;
    margin-bottom: 16px;
  }
  .component-tit {
    font-size: 18px;
    line-height: 18px;
    color: #333;
    font-weight: bold;
    padding-left: 6px;
    margin: 4px 0 10px;
    border-left: 6px solid @color-theme;
    .mixinFlex(space-between; center);
    .back {
      color: #999;
      font-size: 14px;
      cursor: pointer;
      font-weight: normal;
      span {
        padding-right: 4px;
      }
    }
  }
  .check {
    font-size: 14px;
    line-height: 24px;
    padding: 10px 0;
    .ant-col {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .l {
      color: #333;
      padding-left: 16px;
      position: relative;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        opacity: 0.5;
        background-color: @color-theme;
        position: absolute;
        left: 0;
        top: 6px;
      }
    }
    .r {
      color: #666;
      &.status {
        color: @color-theme;
        font-size: 18px;
      }
      &.down {
        color: @color-theme;
        cursor: pointer;
      }
      .score {
        font-size: 18px;
      }
    }
  }
  .form-box {
    padding-top: 10px;
    .ant-form-item {
      margin-bottom: 16px;
      ::v-deep(.ant-form-item-label) {
        & > label {
          color: #999;
        }
      }
      .prefix-icon {
        width: 22px;
        height: 22px;
      }
    }
  }
  .avatar-box {
    .mixinFlex(space-between; center);
    .left {
      width: 90px;
      height: 120px;
      border-radius: 6px;
      font-size: 0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: calc(100% - 104px);
    }
  }
}
</style>
